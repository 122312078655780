import React from "react";
import moment from 'moment';
import Moment from 'react-moment';
import 'moment-timezone';

const InvoiceLifecycleButton = (props) => {
  const {dateAttr, dateVal, label, after, onLifecycleChange} = props;

  const handleClick = (ev) => {
    ev.preventDefault();
    onLifecycleChange(dateAttr, moment());
  }

  if (dateVal) {
    return pug`
      Moment(format="MM/DD/YYYY")= dateVal
    `;
  } else if (after) {
    return pug`
      a.btn.btn-default(onClick=handleClick)= label
    `;
  } else {
    return null;
  }
}

export default InvoiceLifecycleButton;
