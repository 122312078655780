import * as React from 'react';
import { assign, omit, reject } from 'lodash';
import { backend } from './backend';

const InvoicesContext = React.createContext();

// TODO: It would be better to poll with a job id
// so that we can display errors if something goes wrong.
// (Unless we want to just attach errors to the invoice.)
function scheduleCheckInvoiceStatus(dispatch, id) {
  const timer = setInterval(() => {
    backend.get(`/invoices/${id}`)
    .then(resp => {
      if (resp.status == 200) {
        const invoice = resp.data;
        dispatch({type: 'CHECK_INVOICE_STATUS_FINISHED', invoice});
        if (invoice.pdfFileName) dispatch({type: 'UNSCHEDULE_CHECK_INVOICE_STATUS', id: invoice.id});
      } else {
        console.error("Error getting invoice status: " + resp.status);
      }
    }).catch(err => {
      console.error("Error getting invoice status: ", err);
    });
  }, 3000);
  dispatch({type: 'SCHEDULE_CHECK_INVOICE_STATUS', id: id, timer});
}

// Emails: We don't track all the state about the email.
// Keeping that in the modal with useState is simpler
// (and way faster when typing in the text boxes).
// Here we just track whether to show the modal or not
// (and which invoice it's for).
// Also we have a function to set sentAt on the appropriate invoice.
function newEmail(dispatch, setFlash, invoice) {
  dispatch({type: 'NEW_EMAIL_START', invoiceId: invoice.id});
}

function cancelEmail(dispatch) {
  dispatch({type: 'NEW_EMAIL_CANCEL'});
}

function sendEmail(dispatch, attrs) {
  dispatch({type: 'SEND_EMAIL_FINISHED', updatedInvoice: attrs});
}

function addInvoice(dispatch, setFlash, attrs) {
  dispatch({type: 'CREATE_INVOICE_START', attrs});
  backend.post('/invoices', { invoice: attrs })
  .then(resp => {
    if (resp.status == 200 && resp.data.success) {
      dispatch({type: 'CREATE_INVOICE_FINISHED', newInvoice: resp.data.success});
      scheduleCheckInvoiceStatus(dispatch, resp.data.success.id);
    } else {
      dispatch({type: 'CREATE_INVOICE_FAILED', errors: resp.data.errors});
    }
  }).catch(err => {
    console.error("Error adding invoice", err);
    setFlash({alert: "Error updating invoice: " + err});
  });
}

function updateInvoice(dispatch, setFlash, invoice, updates) {
  dispatch({type: 'UPDATE_INVOICE_START', updates});
  backend.patch(`/invoices/${invoice.id}`, { invoice: updates })
  .then(resp => {
    if (resp.status == 200 && resp.data.success) {
      dispatch({type: 'UPDATE_INVOICE_FINISHED', updatedInvoice: resp.data.success});
    } else {
      setFlash({alert: resp.data.errors.join(";\n")});
      // dispatch({type: 'UPDATE_INVOICE_FAILED', errors: resp.data.errors});
    }
  }).catch(err => {
    console.error("Error updating invoice", err);
    setFlash({alert: "Error updating invoice: " + err});
    // dispatch({type: 'UPDATE_INVOICE_FAILED', errors: [err]});
  });
}

function redoInvoice(dispatch, setFlash, invoice) {
  dispatch({type: 'REDO_INVOICE_START'});
  backend.post(`/invoices/${invoice.id}/redo`)
  .then(resp => {
    if (resp.status == 200 && resp.data.success) {
      dispatch({type: 'REDO_INVOICE_FINISHED', oldId: invoice.id, newInvoice: resp.data.success});
      scheduleCheckInvoiceStatus(dispatch, resp.data.success.id);
    } else {
      setFlash({alert: resp.data.errors.join(";\n")});
      // dispatch({type: 'REDO_INVOICE_FAILED', errors: resp.data.errors});
    }
  }).catch(err => {
    console.error("Error redoing invoice", err);
    setFlash({alert: "Error redoing invoice: " + err});
    // dispatch({type: 'REDO_INVOICE_FAILED', errors: [err]});
  });
}

function deleteInvoice(dispatch, setFlash, invoice) {
  dispatch({type: 'DELETE_INVOICE_START'});
  backend.delete(`/invoices/${invoice.id}`)
  .then(resp => {
    if (resp.status == 200 && resp.data.success) {
      dispatch({type: 'DELETE_INVOICE_FINISHED', oldId: invoice.id});
    } else {
      setFlash({alert: resp.data.errors.join(";\n")});
      // dispatch({type: 'DELETE_INVOICE_FAILED', errors: resp.data.errors});
    }
  }).catch(err => {
    console.error("Error redoing invoice", err);
    setFlash({alert: "Error deleting invoice: " + err});
    // dispatch({type: 'DELETE_INVOICE_FAILED', errors: [err]});
  });
}

function ok(state, invoices) {
  return {
    ...state,
    status: 'ok',
    errors: null,
    invoices
  };
}

function invoicesReducer(state, action) {
  const { invoices, status, error } = state;
  let o;
  let timer;
  switch (action.type) {
    case 'CREATE_INVOICE_START':
    case 'REDO_INVOICE_START':
    case 'UPDATE_INVOICE_START':
    case 'DELETE_INVOICE_START':
      return {...state, status: 'submitting'};
    case 'CREATE_INVOICE_FINISHED':
      o = action.newInvoice;
      return ok(state,
        {
          ...invoices,
          byId: assign({}, invoices.byId, {[o.id]: o}),
          all: [ ...invoices.all, o.id ],
        }
      );
    case 'UPDATE_INVOICE_FINISHED':
      return ok(state,
        // TODO: If I use this all/byId structure everywhere,
        // I should define a few functions to add/update/delete a record, and wrap up all this lodash stuff:
        {
          ...invoices,
          byId: assign({}, invoices.byId, {[action.updatedInvoice.id]: action.updatedInvoice}),
        }
      );
    case 'CREATE_INVOICE_FAILED':
      return {
        ...state,
        status: 'err',
        errors: action.errors,
      };
    case 'UPDATE_INVOICE_FAILED':
    case 'REDO_INVOICE_FAILED':
    case 'DELETE_INVOICE_FAILED':
      // TODO: show an error message
      return state;
    case 'REDO_INVOICE_FINISHED':
      o = action.newInvoice;
      return ok(state,
        {
          ...invoices,
          byId: assign(omit(invoices.byId, action.oldId), {[o.id]: o}),
          all: invoices.all.map(invId => (invId == action.oldId ? o.id : invId)),
        }
      );
    case 'DELETE_INVOICE_FINISHED':
      return ok(state,
        {
          ...invoices,
          byId: omit(invoices.byId, action.oldId),
          all: reject(invoices.all, (invId) => invId == action.oldId),
        }
      );
    case 'NEW_EMAIL_START':
      return {...state, email: { invoiceId: action.invoiceId, sending: false }};
    case 'NEW_EMAIL_CANCEL':
      return {...state, email: null};
    case 'SEND_EMAIL_FINISHED':
      return {
        ...state,
        email: null,
        invoices: {
          ...invoices,
          byId: assign({}, invoices.byId, {[action.updatedInvoice.id]: action.updatedInvoice}),
        }
      };
    case 'SCHEDULE_CHECK_INVOICE_STATUS':
      // If there is already a timer for this invoice, cancel it:
      timer = state.timers[action.id];
      if (timer) clearInterval(timer);

      return {
        ...state,
        timers: {
          ...state.timers,
          [action.id]: action.timer,
        }
      };
    case 'UNSCHEDULE_CHECK_INVOICE_STATUS':
      // TODO: You're not really supposed to do side effects in a resolver,
      // but it's hard to get a reference to the timer otherwise:
      timer = state.timers[action.id];
      if (timer) clearInterval(timer);

      return {
        ...state,
        timers: omit(state.timers, action.id),
      };
    case 'CHECK_INVOICE_STATUS_FINISHED':
      o = action.invoice;
      // Don't update it if it was deleted already:
      const oldInv = invoices.byId[o.id];
      if (!oldInv) return state;
      return {
        ...state,
        invoices: {
          ...invoices,
          byId: {...invoices.byId, [oldInv.id]: {...oldInv, ...o}},
        },
      };
    default:
      throw {message: "Unhandled action type", action};
  }
}

function InvoicesProvider({invoices, children}) {
  const [state, dispatch] = React.useReducer(invoicesReducer, {invoices, status: 'ok', error: null, timers: {}, newEmail: null});
  // TODO: Consider memoizing here according to https://kentcdodds.com/blog/how-to-use-react-context-effectively
  const value = {state, dispatch};
  return <InvoicesContext.Provider value={value}>{children}</InvoicesContext.Provider>;
}

function useInvoices() {
  const context = React.useContext(InvoicesContext);
  if (context === undefined) {
    throw new Error('useInvoices must be used within an InvoiceProvider')
  }
  return context;
}

export {InvoicesProvider, useInvoices, addInvoice, updateInvoice, redoInvoice, deleteInvoice, newEmail, cancelEmail, sendEmail};
