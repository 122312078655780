import React from "react";
import PropTypes from "prop-types";

/**
 * TODO: Make this more accessible to the rest of the app,
 * driven off Redux state instead,
 * so that anything can dispatch an action to set the flash message.
 * And instead of data-dismiss, use Redux actions to clear it.
 */
const FlashMessages = ({flash}) => {
  // TODO: It would be more correct to dispatch an action when they click the close button.
  return pug`
    if flash.alert
      .alert.alert-danger
        button.close(type="button" data-dismiss="alert") &times;
        = flash.alert
    if flash.notice
      .alert.alert-success
        button.close(type="button" data-dismiss="alert") &times;
        = flash.notice
  `;
}

FlashMessages.propTypes = {
  flash: PropTypes.shape({
    alert: PropTypes.string,
    notice: PropTypes.string,
  })
};

export default FlashMessages;
