import * as React from 'react';
import { assign, omit, reject } from 'lodash';

const FlashContext = React.createContext();

function FlashProvider({children}) {
  const [flash, setFlash] = React.useState(null);
  const value = {flash, setFlash};
  return <FlashContext.Provider value={value}>{children}</FlashContext.Provider>;
}

function useFlash() {
  const context = React.useContext(FlashContext);
  if (context === undefined) {
    throw new Error('useFlash must be used within an InvoiceProvider')
  }
  return context;
}

const Flash = () => {
  const {flash, setFlash} = useFlash();
  // TODO: This is usually off-screen, e.g. when generating an invoice.
  // Maybe I should give it fixed position and hover over things then fade,
  // like a toast on Android.
  // Alternately, don't use flash for those kinds of errors,
  // but have a generic Errors component I can pass the errors to.
  return flash && pug`
    if flash.alert
      .alert.alert-danger
        button.close(type="button" data-dismiss="alert") &times;
        = flash.alert
    if flash.notice
      .alert.alert-success
        button.close(type="button" data-dismiss="alert") &times;
        = flash.notice
  `;
}

export {FlashProvider, useFlash, Flash};
