import humps from "humps";
import moment from "moment";
import axios from "axios";
import { isArray, isObject, mapValues } from "lodash";

const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
axios.defaults.headers.common['Accept'] = 'application/json';

const inferInvoiceStatus = (inv) => {
  if (!inv.sentAt) return 'unsent';
  else if (!inv.paidAt) return 'sent';
  else if (!inv.depositedAt) return 'paid';
  else return 'deposited';
}

const hydrateInvoice = (inv) => _.assign({}, inv, {
  startDay: moment(inv.startDay),
  endDay: moment(inv.endDay),
  sentAt: inv.sentAt ? moment(inv.sentAt) : null,
  paidAt: inv.paidAt ? moment(inv.paidAt) : null,
  depositedAt: inv.depositedAt ? moment(inv.depositedAt) : null,
  status: inferInvoiceStatus(inv),
});

const momentToIso8601 = (v) => {
  if (moment.isMoment(v)) return v.utc().format();
  else if (isArray(v))  return v.map(momentToIso8601);
  else if (isObject(v)) return mapValues(v, momentToIso8601);
  else return v;
}

const backend = axios.create({
  // baseURL: '',     // Might need to set this differently for dev/test/ci/staging/prod
  headers: {
    'X-CSRF-Token': csrfToken,
    'Content-Type': 'application/json; charset=utf-8',
    'Accept':       'application/json',
  },
  transformRequest: [
    (data, headers) => JSON.stringify(
      humps.decamelizeKeys(momentToIso8601(data))
    )
  ],
  transformResponse: [
    (data) => {
      const o = JSON.parse(data);
      return o.success
        ? _.assign(o, {success: hydrateInvoice(o.success)})
        : o;
    }
  ],
});

export { hydrateInvoice, backend };
