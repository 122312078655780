import React from 'react';

const Errors = ({errors}) => {
  if (errors) {
    return pug`
      .alert.alert-danger
        each err, i in errors
          div(key=i)= err
    `;
  } else {
    return null;
  }
}

export default Errors;
