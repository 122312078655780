import React, { Fragment, ChangeEvent, useEffect, useState, useContext } from 'react';
import { Router, BrowserRouter, Switch, Route, Link, useLocation, useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Flash } from '../shared/flashContext';
import Filters from './Filters';
import InvoiceList from "./InvoiceList";
import New from './New';
import NewEmail from './NewEmail';

function useInvoiceFilters(params) {
  if (!params.window) params.window = 'recent';

  switch (params.window) {
    case 'recent':
      params.duration = params.duration ? parseInt(params.duration) : 60;
      params.month = null;
      params.year = null;
      break;
    case 'month':
      params.duration = null;
      params.month = params.month ?  moment(params.month + "-01") : moment().startOf('month');
      params.year = null;
      break;
    case 'year':
      params.duration = null;
      params.month = null;
      params.year = params.year ? moment(params.year  + "-01-01") : moment().startOf('year');
      break;
    default:
      throw {message: 'Invalid filter window', params};
  }

  if (params.clientId) params.clientId = parseInt(params.clientId);

  return params;
}

function filtersAsQueryString(filters) {
  // Note IE 11 doesn't support URLSearchParams! But Edge does.
  const ret = new URLSearchParams();

  ret.append("window", filters.window);

  switch (filters.window) {
    case 'recent':
      ret.append("duration", String(filters.duration));
      ret.delete("month");
      ret.delete("year");
      break;
    case 'month':
      ret.delete("duration");
      ret.append("month", filters.month.format("YYYY-MM"));
      ret.delete("year");
      break;
    case 'year':
      ret.delete("duration");
      ret.delete("month");
      ret.append("year", filters.year.format("YYYY"));
      break;
    default:
      throw {message: 'Invalid filter window', filters};
  }

  if (filters.clientId) ret.append("clientId", String(filters.clientId));
  else ret.delete("clientId");

  if (filters.status) ret.append("status", String(filters.status));
  else ret.delete("status");

  return ret.toString();
}

const Index = (props) => {
  const { clients } = props;
  const history = useHistory();
  // TODO: maybe a reducer here instead?:
  const [filters, setFilters] = useState(useInvoiceFilters(Object.fromEntries(new URLSearchParams(history.location.search).entries())));

  function onFilterChange(action) {
    switch (action.type) {
      case 'SET_DURATION_FILTER':
        setFilters({...filters, window: 'recent', duration: action.duration, month: null,         year: null});
        break;
      case 'SET_MONTH_FILTER':
        setFilters({...filters, window: 'month',  duration: null,            month: action.month, year: null});
        break;
      case 'SET_YEAR_FILTER':
        setFilters({...filters, window: 'year',   duration: null,            month: null,         year: action.year});
        break;
      case 'SET_CLIENT_FILTER':
        setFilters({...filters, clientId: action.clientId});
        break;
      case 'SET_STATUS_FILTER':
        setFilters({...filters, status: action.status});
        break;
      default:
        throw {message: "Invalid filter change: ", action: action};
    }
  }

  useEffect(() => {
    history.push({search: filtersAsQueryString(filters)});
  }, [filters, history]);

  return pug`
    Fragment
      .fixed-toolbar
        .container
          .row
            .col-md-12
              Filters(filters=filters, onFilterChange=onFilterChange, clients=clients)
      .container.under-toolbar
        Flash
        .row
          .col-md-12
            InvoiceList(filters=filters)
            NewEmail

            h3 New Invoice
            New(clients=clients)
  `;
}

export default Index;
