import React from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import MomentPropTypes from 'react-moment-proptypes';
import InvoiceRow from "./InvoiceRow";
import {useInvoices} from './context.js';

// TODO: move this whole file into Index

/*
 * TODO:
 * - Since npm is already including lodash, use that instead of underscore.
 * + Clicking the invoice number should take you to a summary of that invoice. (Okay to do server-rendered for now.)
 *   - Need to fix the display of that page though...
 * + probably remove the InvoiceList component....
 * + send/receive/deposit buttons work.
 * - JS to check on pdf status works.
 * + add New Invoice form.
 * + When filters change, update the URL so that on a reload you get back to where you were.
 * - Write some tests:
 *   - initial load works
 *   - filtering works
 *   - new invoice works
 *   - send/receive/deposit buttons work
 *   - "due at" color works
 * - Only load a year of invoices at a time, going to the server when necessary.
 * + Use Redux (or Context?) to store the list of clients,
 *   so that we notice when it changes?
 *   Meh, not really needed since we go back to the server on each nav click.
 * - Some quick way to set sent/received/deposited but on a different day.
 * - Show spinner until the invoices have loaded.
 * + Buttons to edit/redo/delete
 */

const getVisibleInvoices = (invoices, filter) => {
  let ret = invoices.all.map(id => invoices.byId[id]);
  if (!filter) return ret;
  if (filter.window == 'recent') {
    const t = moment().subtract(filter.duration, 'days');
    ret = ret.filter(inv => inv.endDay.isSameOrAfter(t));
  } else if (filter.window == 'month') {
    const ts = filter.month;
    const te = ts.clone().add(1, "month");
    ret = ret.filter(inv => inv.endDay.isBetween(ts, te, null, '[)'));
  } else if (filter.window == 'year') {
    const ts = filter.year;
    const te = ts.clone().add(1, "year");
    ret = ret.filter(inv => inv.endDay.isBetween(ts, te, null, '[)'));
  }

  if (filter.clientId) {
    ret = ret.filter(inv => inv.clientSnapshot.clientId == filter.clientId);
  }

  if (filter.status) {
    ret = ret.filter(inv => inv.status == filter.status);
  }

  return ret;
}

const InvoiceList = ({filters, onStageClick}) => {
  const { state } = useInvoices();
  const { invoices } = state;
  const visibleInvoices = getVisibleInvoices(invoices, filters);

  return pug`
    table.table.table-striped#invoices-table
      thead
        tr
          th Number
          th Date
          th Client
          th Amount
          th PDF
          th Sent
          th Received
          th Deposited
          th
      tbody
        each inv in visibleInvoices
          InvoiceRow(key=inv.id invoice=inv)
  `
}

export default InvoiceList;
