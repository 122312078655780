import React, { ChangeEvent, useEffect, useState, useContext } from 'react';
import { Router, BrowserRouter, Switch, Route, Link, useLocation, useParams, useHistory } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { mapValues } from 'lodash';
import { FlashProvider, useFlash } from '../shared/flashContext';
import { InvoicesProvider } from '../invoices/context';
import { hydrateInvoice } from '../invoices/backend';
import Index from '../invoices/Index';

const InvoiceAppBody = (props) => {
  const { clients, invoices, flash } = props;
  const { setFlash } = useFlash();

  // TODO: Do this the right way:
  setTimeout(() => setFlash(flash), 1);

  // Convert invoice data to moment dates, etc:
  const liveInvoices = {...invoices, byId: mapValues(invoices.byId, hydrateInvoice)};
  // console.log("invoices", liveInvoices);

  // TODO: ClientsProvider
  return pug`
    InvoicesProvider(invoices=liveInvoices)
      Switch
        Route(path="/invoices")
          Index(clients=clients)
        Route(path="/invoices/:invoiceId")
  `;
}

const history = createBrowserHistory();

const InvoiceApp = (props) => {
  return pug`
    Router(history=history)
      FlashProvider
        InvoiceAppBody(...props)
  `;
}

export default InvoiceApp;
