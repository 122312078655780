import React, { useState } from "react";
import { sortBy } from 'lodash';
import { useFlash } from '../shared/flashContext';
import { useInvoices, addInvoice } from './context';
import Errors from '../shared/Errors';

const clientList = (clients) => {
  return sortBy(
    clients.all.map(id => clients.byId[id]).filter(c => !c.archived),
    c => c.name);
}

const New = ({clients}) => {

  const {flash, setFlash} = useFlash();
  const {state, dispatch} = useInvoices();
  const sortedClients = clientList(clients);
  const [clientId, setClientId] = useState(sortedClients[0] ? sortedClients[0].id : null);
  const [attrs, setAttrs] = useState(null);
  const submitting = state.status === 'submitting';

  const handleSubmit = (event) => {
    event.preventDefault();
    if (clientId) addInvoice(dispatch, setFlash, {clientId, ...attrs});
    hideDialog();
  }

  const handleSelect = (event) => {
    setClientId(event.target.value);
  }

  const showDialog = (event) => {
    setAttrs({startDay: '', endDay: ''})
  }

  const hideDialog = (event) => {
    setAttrs(null);
  }

  const handleDialogChange = (event) => {
    setAttrs((old) => {
      return {...old, [event.target.name]: event.target.value};
    });
  }

  return pug`
    form#new-invoice.form-inline(onSubmit=handleSubmit)
      Errors(errors=state.errors)
      select.form-control(name="clientId" value=clientId onChange=handleSelect)
        each c in sortedClients
          option(key=c.id value=c.id)= c.name
      = " "
      .btn-group
        input.btn.btn-primary(type='submit' disabled=submitting value='Generate')
        button.btn.btn-primary.dropdown-toggle(type='button' disabled=submitting data-toggle='dropdown' aria-haspopup='true' aria-expanded='false')
          span.caret
          span.sr-only Toggle Dropdown
        ul.dropdown-menu
          li
            a(onClick=handleSubmit) Generate with defaults
          li
            a(onClick=showDialog) Generate with overrides

    if attrs
      .modal.fade.in.show(tabIndex="-1" role="dialog")
        .modal-dialog(role="document")
          .modal-content
            .modal-header
              button(type="button" class="close" data-dismiss="modal" aria-label="Close" onClick=hideDialog)
                span(aria-hidden="true") &times;
              h4.modal-title Generate Invoice
            .modal-body
              .row
                .col-md-12
                  form
                    .form-group
                      label(for="startDay") Start Day
                      input.form-control#startDay(type="text" name="startDay" value=attrs.startDay onChange=handleDialogChange)
                    .form-group
                      label(for="endDay") End Day
                      input.form-control#endDay(type="text" name="endDay" value=attrs.endDay onChange=handleDialogChange)
            .modal-footer
              button.btn.btn-default(type="button" data-dismiss="close" onClick=hideDialog) Cancel
              button.btn.btn-primary(onClick=handleSubmit) Generate
  `;
}

export default New;
