import React, { ChangeEvent, useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from 'moment';
import MomentPropTypes from 'react-moment-proptypes';
import { mapUrlToProps } from '../util/invoices';

const clientFilterHuman = (clientId, clients) => {
  if (clientId) {
    return clients.byId[clientId].name;
  } else {
    return "all clients";
  }
}

const statusFilterHuman = (status) => {
  if (status) {
    return status;
  } else {
    return 'any status';
  }
}

const clientList = (clients) => {
  return _.sortBy(
    clients.all.map(id => clients.byId[id]),
    c => c.name);
}

const InvoiceFilters = (props) => {
  const { filters, onFilterChange, clients } = props;

  const duration = filters.duration || 60;
  const month = filters.month || moment().startOf('month');
  const year = filters.year || moment().startOf('year');
  const status = filters.status;
  const clientId = filters.clientId;

  const changeDurationFilter = (duration) => onFilterChange({type: 'SET_DURATION_FILTER', duration});
  const changeMonthFilter = (month) => onFilterChange({type: 'SET_MONTH_FILTER', month});
  const changeYearFilter = (year) => onFilterChange({type: 'SET_YEAR_FILTER', year});
  const changeClientIdFilter = (clientId) => onFilterChange({type: 'SET_CLIENT_FILTER', clientId});
  const changeStatusFilter = (status) => onFilterChange({type: 'SET_STATUS_FILTER', status});

  return pug`
    nav.btn-toolbar
      .btn-group
        button.btn.btn-default.dropdown-toggle(data-toggle="dropdown" style={borderRadius:'4px'})
          | last ${duration} days #[span.caret]
        ul.dropdown-menu
          li
            a(onClick=${()=>changeDurationFilter(7)}) last 7 days
          li
            a(onClick=${()=>changeDurationFilter(14)}) last 14 days
          li
            a(onClick=${()=>changeDurationFilter(30)}) last 30 days
          li
            a(onClick=${()=>changeDurationFilter(60)}) last 60 days
          li
            a(onClick=${()=>changeDurationFilter(90)}) last 90 days
          li
            a(onClick=${()=>changeDurationFilter(120)}) last 120 days
      .btn-group
        a.btn.btn-default(onClick=${()=>changeMonthFilter(month.clone().subtract(1, 'month'))})
          i.fa.fa-chevron-left &nbsp;
        a.btn.btn-default(onClick=${()=>changeMonthFilter(month.clone())})
          = month.format("MMM YYYY")
        a.btn.btn-default(onClick=${()=>changeMonthFilter(month.clone().add(1, 'month'))})
          i.fa.fa-chevron-right &nbsp;
      .btn-group
        a.btn.btn-default(onClick=${()=>changeYearFilter(year.clone().subtract(1, 'year'))})
          i.fa.fa-chevron-left &nbsp;
        a.btn.btn-default(onClick=${()=>changeYearFilter(year.clone())})
          = year.format("YYYY")
        a.btn.btn-default(onClick=${()=>changeYearFilter(year.clone().add(1, 'year'))})
          i.fa.fa-chevron-right &nbsp;
      .btn-group
        button.btn.btn-default.dropdown-toggle(data-toggle='dropdown' style={borderRadius:'4px'})
          = clientFilterHuman(clientId, clients)
          | 
          span.caret
        ul.dropdown-menu
          li(key=0 className=${clientId ? '' : 'active'})
            a(onClick=${()=>changeClientIdFilter(null)}) all clients
          each c in clientList(clients)
            li(key=c.id className=${clientId == c.id ? 'active' : ''})
              a(onClick=${()=>changeClientIdFilter(c.id)})= c.name
      .btn-group
        button.btn.btn-default.dropdown-toggle(data-toggle="dropdown" style={borderRadius:'4px'})
          = statusFilterHuman(status)
          | 
          span.caret
        ul.dropdown-menu
          li
            a(onClick=${()=>changeStatusFilter(null)}) any status
          li
            a(onClick=${()=>changeStatusFilter('unsent')}) unsent
          li
            a(onClick=${()=>changeStatusFilter('sent')}) sent
          li
            a(onClick=${()=>changeStatusFilter('paid')}) paid
          li
            a(onClick=${()=>changeStatusFilter('deposited')}) deposited
  `;
}

export default InvoiceFilters;
