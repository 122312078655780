import React, { useState } from "react";
import {useInvoices, cancelEmail, sendEmail} from './context';
import { backend } from './backend';
import { useFlash } from '../shared/flashContext';

// TODO: Fix ugly corners (however Bootstrap does it)
// TODO: Make the escape key work

const NewEmail = (props) => {
  const { state, dispatch } = useInvoices();
  const { setFlash } = useFlash();
  const [email, setEmail] = useState(null);

  if (state.email && state.email.invoiceId && !email) {
    backend.get(`/invoices/${state.email.invoiceId}/emails/new`)
      .then(resp => {
        if (resp.status == 200) {
          setEmail({...resp.data, errors: []});
        } else {
          console.error("Error starting email", resp);
          setFlash({alert: "Error starting email."});
        }
      }).catch(err => {
        console.error("Error starting email", err);
        setFlash({alert: "Error starting email: " + err});
      });
  }

  if (!state.email || !email) return null;

  const cancel = () => { setEmail(null); cancelEmail(dispatch); }
  const tosRegex = /^tos\[(\d+)\]\.(role|email)$/;
  const handleChange = (event) => {
    setEmail((old) => {
      const match = event.target.name.match(tosRegex);
      if (match) {
        const [_whole, iStr, attr] = match;
        const i = parseInt(iStr);   // Very important or slice gets really confused!
        const newTos = [...old.invoiceEmailRecipients.slice(0, i),
                        {...old.invoiceEmailRecipients[i], [attr]: event.target.value},
                        ...old.invoiceEmailRecipients.slice(i + 1)];
        return {...old, invoiceEmailRecipients: newTos};
      } else {
        return {...old, [event.target.name]: event.target.value};
      }
    });
  }
  const removeRecipient = (i) => (event) => {
    setEmail((old) => ({...old, invoiceEmailRecipients: [...old.invoiceEmailRecipients.slice(0, i),
                                                         ...old.invoiceEmailRecipients.slice(i + 1)]}));
  }
  const addRecipient = () => {
    setEmail((old) => ({...old, invoiceEmailRecipients: [...old.invoiceEmailRecipients, {role: "to", email: ""}]}));
  }
  // TODO: Add a button to "mark as sent". (Of course you could also just use Edit for that.)
  const send = () => {
    for (const k of ["subject", "from", "body"]) {
      console.log(`${k}: ${email[k]}`);
    }
    for (var i = 0; i < email.invoiceEmailRecipients.length; i++) {
      const recip = email.invoiceEmailRecipients[i];
      console.log(`tos[${i}].role: ${recip.role}`);
      console.log(`tos[${i}].email: ${recip.email}`);
    }
    const { from, subject, body, invoiceEmailRecipients } = email;
    backend.post(`/invoices/${state.email.invoiceId}/emails`, { invoiceEmail: { from, subject, body, invoiceEmailRecipientsAttributes: invoiceEmailRecipients } })
      .then(resp => {
        if (resp.status == 200 && resp.data.success) {
          sendEmail(dispatch, resp.data.success);
          setFlash({notice: 'Email sent!'});
        } else {
          setEmail((old) => ({...old, errors: resp.data.errors}));
        }
      }).catch(err => {
        setEmail((old) => ({...old, errors: [err]}));
      });
  }

  return pug`
    .modal.fade.in.show(tabIndex="-1" role="dialog")
      .modal-dialog(role="document")
        .modal-content
          .modal-header
            button(type="button" class="close" data-dismiss="modal" aria-label="Close" onClick=cancel)
              span(aria-hidden="true") &times;
            h4.modal-title Send email
          .modal-body
            if email.errors.length > 0
              .alert.alert-danger
                button.close(type="button" data-dismiss="alert") &times;
                  each err, i in email.errors
                    div(key=i)= err
            .row
              .col-md-12
                form
                  .form-group
                    label(for="emailSubject") Subject
                    input.form-control#emailSubject(type="text" name="subject" value=email.subject onChange=handleChange)
                  .form-group
                    label(for="emailFrom") From
                    input.form-control#emailFrom(type="text" name="from" value=email.from onChange=handleChange)
                  .form-group
                    label To
                    each recip, i in email.invoiceEmailRecipients
                      .row(key=i style={marginBottom: "0.3em"})
                        .col-md-2
                          select.form-control(value=recip.role name="tos[" + i + "].role" onChange=handleChange)
                            each role in ['to', 'cc', 'bcc']
                              option(key=role value=role)= role
                        .col-md-8
                          input.form-control(type="text" name="tos[" + i + "].email" value=recip.email onChange=handleChange)
                        .col-md-2.text-right
                          a.btn.btn-default(onClick=removeRecipient(i)) &ndash;
                    a.btn.btn-default(onClick=addRecipient) &plus;

                  .form-group
                    label(for="emailBody") Body
                    textarea.form-control#emailBody(type="text" name="body" value=email.body onChange=handleChange rows=10)
          .modal-footer
            button.btn.btn-default(type="button" data-dismiss="close" onClick=cancel) Cancel
            button.btn.btn-primary(onClick=send) Send

  `;
};

export default NewEmail;
