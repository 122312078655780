import React from "react";
import PropTypes from "prop-types";
import Moment from 'react-moment';
import MomentPropTypes from 'react-moment-proptypes';
import 'moment-timezone';
import Money from 'react-money-component'
import LifecycleButton from "./LifecycleButton";
import {useInvoices, updateInvoice, redoInvoice, deleteInvoice, newEmail} from './context';
import {useFlash} from '../shared/flashContext';

/*
 * TODO:
 * - DRY up with invoice list
 * - line items list
 * - invoice number should be a link taking you to the invoice show page. Needs a router?
 * - Supposedly performance is better if we only pass an id prop and pull the invoice out of state
 */
const InvoiceRow = ({invoice}) => {
  const {state, dispatch} = useInvoices();
  const {flash, setFlash} = useFlash();

  function onStageClick(dateAttr, dateVal) {
    updateInvoice(dispatch, setFlash, invoice, {[dateAttr]: dateVal});
  }

  const handleInvoiceEmailClick = (event) => {
    event.preventDefault();
    // Ask for the template info
    // Show the modal
    newEmail(dispatch, setFlash, invoice);
  }

  function dueClass() {
    if (invoice.overdue)      return 'text-danger';
    else if (invoice.dueSoon) return 'text-warning';
    else                      return 'text-success';
  }

  return pug`
    tr
      td
        a(href="/invoices/" + invoice.id)= invoice.invoiceNumber
      td
        Moment(format="MM/DD/YYYY")= invoice.startDay
        = " to "
        Moment(format="MM/DD/YYYY")= invoice.endDay
      td= invoice.clientSnapshot.name
      td.text-right
        Money(cents=invoice.totalAmountCents currency="USD")
      td
        if invoice.pdfFileName
          a(href=('/invoices/' + invoice.id + '/pdf'))= invoice.pdfFileName
        else
          span.invoice-job-status= invoice.pdfJobStatus
      td
        if invoice.clientSnapshot.hasInvoiceEmailTemplate && !invoice.sentAt
          a.btn.btn-default(onClick=handleInvoiceEmailClick)= "send"
        else
          LifecycleButton(dateAttr="sentAt" dateVal=invoice.sentAt label="send" after=true onLifecycleChange=onStageClick)
      td
        LifecycleButton(dateAttr="paidAt" dateVal=invoice.paidAt label="receive" after=invoice.sentAt onLifecycleChange=onStageClick)
      td
        if invoice.paidAt
          LifecycleButton(dateAttr="depositedAt" dateVal=invoice.depositedAt label="deposit" after=invoice.paidAt onLifecycleChange=onStageClick)
        else
          span(className=dueClass())
            | due
            | 
            Moment(format="MM/DD" add={days: invoice.clientSnapshot.paymentDue})= invoice.endDay
      td(style={whiteSpace: "nowrap"})
        a.btn.btn-default(href=('/invoices/' + invoice.id + '/edit')) edit
        = " "
        a.btn.btn-default(onClick=${() => window.confirm("Really redo the invoice?") && redoInvoice(dispatch, setFlash, invoice)} rel="nofollow") redo
        = " "
        a.btn.btn-danger(onClick=${() => window.confirm("Really delete the invoice?") && deleteInvoice(dispatch, setFlash, invoice)} rel="nofollow" style={pointerEvents: invoice.deleteInProgress ? 'none' : null}) delete
  `;
}

export default InvoiceRow;
